import React, { FC, useState } from 'react';
import clsx from 'clsx';
import { Theme, makeStyles } from '@material-ui/core';
import { Button } from '@material-ui/core';

import { SearchInput } from '../../components';
import { getChaiUserInfo } from '../../lib/chaiAPI';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

type Props = {
  className?: string,
  userInfo: IssueCardInput,
  setUserInfo: React.Dispatch<React.SetStateAction<IssueCardInput>>,
  setIsAccountDetailsVisible: React.Dispatch<React.SetStateAction<boolean>>
}
const UsersToolbar: FC<Props> = ({ className, userInfo, setUserInfo, setIsAccountDetailsVisible }) => {
  const classes = useStyles();

  const [customerId, setCustomerId] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomerId(event.target.value);
  };

  const onSearchClick = async () => {
    const result = await getChaiUserInfo(customerId);
    console.log(result)
    setUserInfo({
      ...userInfo,
      ...result
    })
    setIsAccountDetailsVisible(true);
  };

  return (
    <div
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
        <span className={classes.spacer} />
      </div>
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder="User ID"
          handleChange={handleChange}
          value={customerId}
        />
        <Button
          color="primary"
          variant="contained"
          onClick={onSearchClick}
        >
          Search 
        </Button>
      </div>
    </div>
  );
};

export default UsersToolbar;
