import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import AddCardToolbar from './AddCardToolbar';
import AccountDetails from './AccountDetails';

const useStyles = makeStyles((theme: any) => ({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const Account = () => {
  const classes = useStyles();
  const [userInfo, setUserInfo] = useState({
    id: '',
    productName: '',
    korName: '',
    engName: '',
    birthday: '',
    gender: '1',
    isForeigner: '2',
    phoneNumber: '',
    ci: '',
    address: '',
    zipcode: '',
    password: '',
    passwordConfirm: '',
  });
  const [isAccountDetailsVisible, setIsAccountDetailsVisible] = useState(false);

  return (
    <div className={classes.root}>
      <AddCardToolbar userInfo={userInfo} setUserInfo={setUserInfo} setIsAccountDetailsVisible={setIsAccountDetailsVisible}/>
      {isAccountDetailsVisible &&
        <div className={classes.content}>
          <AccountDetails userInfo={userInfo} setUserInfo={setUserInfo} />
        </div>
      }
    </div>
  );
};

export default Account;
