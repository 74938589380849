import axios from "axios";
import { get } from "lodash";

const client = axios.create({
  baseURL: "https://api-dev.chai.finance",
  responseType: "json"
});

export const getChaiUserInfo = async (koreaSpecId: string) => {
  const result = await client.get(`/v1/card/user/${koreaSpecId}`, {
    headers: {
      'Private-API-Key': '97d1de70-189b-4c64-a4dd-8d7af44ba8a4'
    }
  });
  const gender = get(result, 'data.gender', undefined);
  console.log(gender)

  return {
    id: get(result, 'data.phone', undefined),
    phoneNumber: get(result, 'data.phone', undefined),
    ci: get(result, 'data.koreaSpec.CI', undefined),
    birthday: get(result, 'data.birthday', undefined),
    gender: (gender === 'male') ? '1' : '2',
    korName: get(result, 'data.fullname', undefined),
  }
}