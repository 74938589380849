import client from './client';
import { get } from 'lodash';

export default async (input: GetCardUserInfoInput): Promise<GetCardUserInfoReturn> => {
  if (!input.csno && !input.cardNo) {
    throw new Error(`회원정보 조회 실패 - 입력값이 없습니다`)
  }

  return client.post(`/root/card/cardMemberSelect`, input).then(res => {
    console.log(input)

    const cardUserInfo = get(res, 'data.CardMember');
    console.log(res)

    if (!cardUserInfo) {
      throw new Error(`회원정보 조회 실패`)
    }

    return {
      csno: cardUserInfo.csno,
      custKorName: cardUserInfo.custKorName,
      custEngName: cardUserInfo.custEngName,
      birthDt: cardUserInfo.birthDt,
      sexDvsnCd: cardUserInfo.sexDvsnCd,
      foreignerDvsnCd: cardUserInfo.foreignerDvsnCd,
      phone: `${cardUserInfo.phoneAtno}-${cardUserInfo.phoneBtno}-${cardUserInfo.phoneTlno}`,
      emalAddr: cardUserInfo.emalAddr,
      addr: cardUserInfo.owhsNewAddr,
      zipCode: cardUserInfo.owhsZpcd
    }
  }).catch(e => {
    throw e 
  });
}