import React, { FC, useState, ChangeEvent } from 'react';
import { withStyles, makeStyles, Theme } from '@material-ui/core';
import {
  Card,
  CardHeader,
  CardActions,
  Grid,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Button
} from '@material-ui/core';
import { Queue, Delete, RecordVoiceOver, ShoppingCart } from '@material-ui/icons';
import { accident, approve, getUserInfo, issueCard } from '../../lib/cardApi'
import { CardDialog } from '../../components'
import { useToast } from '../../components/ToastProvider';

const StyledTableCell = withStyles((theme: Theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      // backgroundColor: theme.palette.background.default,
      backgroundColor: '#F4F6F8',
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  },
  icon: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
}));

type Props = {
  cardInfo: CardInfo,
  merchants: Merchant[],
  cardUserInfo: GetCardUserInfoReturn,
  setCardInfo: React.Dispatch<React.SetStateAction<CardInfo>>,
  refreshCardInfo: (cardNumber: string) => Promise<void>,
  refreshApprovals: (cardNumber: string) => Promise<void>,
  refreshAccidents: (cardNumber: string) => Promise<void>,
}
const CardInfo: FC<Props> = ({ merchants, cardInfo, setCardInfo, cardUserInfo, refreshCardInfo, refreshApprovals, refreshAccidents }) => {

  const classes = useStyles();
  const [approvalOpen, setApprovalOpen] = useState(false);
  const [registerOpen, setRegisterOpen] = useState(false);
  const [cancelCardOpen, setCancelCardOpen] = useState(false);
  const [reportLossOpen, setReportLossOpen] = useState(false);
  const [reissueCardOpen, setReissueCardOpen] = useState(false);
  const [cancelLossOpen, setCancelLossOpen] = useState(false);
  const { showToast } = useToast();

  const openRegister = () => {
    setRegisterOpen(true);
  };

  const openCancelCard = () => {
    setCancelCardOpen(true);
  };

  const openReissueCard = () => {
    setReissueCardOpen(true);
  };

  const openReportLoss = () => {
    setReportLossOpen(true);
  };

  const openCancelLoss = () => {
    setCancelLossOpen(true);
  };


  const onAccidentConfirm = (accidentCode: string) => {
    return async () => {
      try {
        await accident({
          mnbdNbr: cardInfo.cardNo,
          acdntCd: accidentCode,
          reptPrsnNm: cardUserInfo.custKorName,
          cntctTelNbr: cardUserInfo.phone,
        });
        await refreshCardInfo(cardInfo.cardNo);
      } catch (e) {
        showToast(e.message, 'error')
        console.log(e)
      }
    }
  };

  const onReissueConfirm = () => {
    return async () => {
      try {
        const getUserInfoResult = await getUserInfo(cardUserInfo.csno, true, cardInfo.cardNo)
        await issueCard({
          id: cardUserInfo.csno,
          productName: 'CHAI카드',
          korName: cardUserInfo.custKorName,
          engName: cardUserInfo.custEngName,
          birthday: cardUserInfo.birthDt,
          gender: cardUserInfo.sexDvsnCd,
          isForeigner: cardUserInfo.foreignerDvsnCd,
          phoneNumber: cardUserInfo.phone,
          ci: '',
          address: cardUserInfo.addr,
          zipcode: cardUserInfo.zipCode,
          password: '1234',
          passwordConfirm: '1234',
        }, getUserInfoResult.aplnCardNo, cardInfo.valdTermYm, '21', cardInfo.cardNo);
        await refreshCardInfo(cardInfo.cardNo);

      } catch (e) {
        console.log(e)
        showToast(e.message, 'error')
      }
    }
  };



  const openApproval = () => {
    setApprovalOpen(true);
  };

  const handleApprovalClose = () => {
    setApprovalOpen(false);
  };

  const handleApprovalConfirm = async () => {
    try {
      await approve(values, cardInfo.cardNo);
      await refreshApprovals(cardInfo.cardNo);
      showToast('승인 성공', 'success')
    } catch (e) {
      showToast(e.message, 'error')
      console.log(e)
    } finally {
      setApprovalOpen(false);
    };
  };

  const [values, setValues] = useState({
    merchantId: merchants.length > 0 ? merchants[0].id : '',
    taxAmount: '',
    netAmount: '',
    serviceAmount: '',
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Card>
      <form>
        <CardHeader
          title="카드 정보"
        />
        <Divider />
        <Table>
          <TableBody>
            <StyledTableRow >
              <StyledTableCell>카드번호</StyledTableCell>
              <StyledTableCell>{cardInfo.cardNo}</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow >
              <StyledTableCell>상품명</StyledTableCell>
              <StyledTableCell>{cardInfo.prdtName}</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow >
              <StyledTableCell>유효기간</StyledTableCell>
              <StyledTableCell>{cardInfo.valdTermYm}</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow >
              <StyledTableCell>등록일자</StyledTableCell>
              <StyledTableCell>{cardInfo.isucDt}</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow >
              <StyledTableCell>최종카드여부</StyledTableCell>
              <StyledTableCell>{cardInfo.lastCardYn}</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow >
              <StyledTableCell>상태</StyledTableCell>
              <StyledTableCell>{cardInfo.cardStat}</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow >
              <StyledTableCell>사고등재여부</StyledTableCell>
              <StyledTableCell>{cardInfo.acdtStatDvsnCd}</StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
        <Divider />
        <CardActions>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={openRegister}
          >
            <div className={classes.icon}>
              <Queue/>
            </div>
            사용등록 
          </Button>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={openCancelCard}
          >
            <div className={classes.icon}>
              <Delete/>
            </div>
            해지 
          </Button>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={openReissueCard}
          >
            <div className={classes.icon}>
              <Delete/>
            </div>
            재발급
          </Button>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={openReportLoss}
          >
            <div className={classes.icon}>
              <RecordVoiceOver/>
            </div>
            분실신고 
          </Button>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={openCancelLoss}
          >
            <div className={classes.icon}>
              <RecordVoiceOver/>
            </div>
            분실신고해지 
          </Button>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={openApproval}
          >
            <div className={classes.icon}>
              <ShoppingCart/>
            </div>
            승인  
          </Button>
        </CardActions>
      </form>

      <Dialog
        open={approvalOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth='sm'
      >
        <DialogTitle id="alert-dialog-title">{"승인 신청"}</DialogTitle>
        <DialogContent>
          <form
            autoComplete="off"
            noValidate
          >
              <Grid
                container
                spacing={1}
              >
                <Grid
                  item
                  md={12}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="카드번호"
                    margin="dense"
                    variant="outlined"
                    disabled
                    value={cardInfo.cardNo}
                  />
                </Grid>
                <Grid
                  item
                  md={12}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="유효기간"
                    margin="dense"
                    variant="outlined"
                    disabled
                    value={cardInfo.valdTermYm}
                  />
                </Grid>
                <Grid
                  item
                  md={12}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="가맹점"
                    margin="dense"
                    name="merchantId"
                    onChange={handleChange}
                    required
                    variant="outlined"
                    select
                    SelectProps={{ native: true }}
                  >
                    {merchants.map(merchant => (
                      <option
                        key={merchant.id}
                        value={merchant.id}
                      >
                        {merchant.name}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid
                  item
                  md={12}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="승인금액"
                    margin="dense"
                    name="netAmount"
                    onChange={handleChange}
                    required
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={12}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="부가세"
                    margin="dense"
                    name="taxAmount"
                    onChange={handleChange}
                    required
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={12}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="봉사료"
                    margin="dense"
                    name="serviceAmount"
                    onChange={handleChange}
                    required
                    variant="outlined"
                  />
                </Grid>
              </Grid>
          </form>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={handleApprovalClose} color="primary">
            취소
          </Button>
          <Button onClick={handleApprovalConfirm} color="primary" autoFocus>
            확인
          </Button>
        </DialogActions>
      </Dialog>
      <CardDialog
        title="사용등록"
        context="사용등록 하시겠습니까?"
        open={registerOpen}
        setOpen={setRegisterOpen}
        onConfirm={onAccidentConfirm('069')}
      />
      <CardDialog
        title="카드해지"
        context="카드 해지 하시겠습니까?"
        open={cancelCardOpen}
        setOpen={setCancelCardOpen}
        onConfirm={onAccidentConfirm('811')}
      />
      <CardDialog
        title="재발급"
        context="재발급 신청 하시겠습니까?"
        open={reissueCardOpen}
        setOpen={setReissueCardOpen}
        onConfirm={onReissueConfirm()}
      />
      <CardDialog
        title="분실신고"
        context="분실 신고 하시겠습니까?"
        open={reportLossOpen}
        setOpen={setReportLossOpen}
        onConfirm={onAccidentConfirm('111')}
      />
      <CardDialog
        title="분실신고해지"
        context="분실 신고 해지 하시겠습니까?"
        open={cancelLossOpen}
        setOpen={setCancelLossOpen}
        onConfirm={onAccidentConfirm('119')}
      />
    </Card>
  );
};

export default CardInfo;
