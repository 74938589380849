import React, { FC } from 'react';
import clsx from 'clsx';
import { Theme, makeStyles } from '@material-ui/core';
import { Drawer, DrawerProps } from '@material-ui/core';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import ListIcon from '@material-ui/icons/List';

import SidebarNav from './SidebarNav';

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}));

type Props = {
  className?: string,
  onClose?: () => void,
  open: boolean,
  variant: DrawerProps['variant']
};

const Sidebar: FC<Props> = ({ open, variant, onClose, className }) => {
  const classes = useStyles();

  const pages = [
    {
      title: 'Add Card',
      href: '/addcard',
      icon: <NoteAddIcon />
    },
    {
      title: 'Customer',
      href: '/customer',
      icon: <AccountBoxIcon />
    },
    {
      title: 'Card Detail',
      href: '/carddetail',
      icon: <CreditCardIcon />
    },
    {
      title: 'Merchant List',
      href: '/merchantlist',
      icon: <ListIcon />
    },
  ];

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div
        className={clsx(classes.root, className)}
      >
        <SidebarNav
          className={classes.nav}
          pages={pages}
        />
      </div>
    </Drawer>
  );
};

export default Sidebar;
