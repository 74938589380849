import React, { FC, ChangeEvent, useEffect } from 'react';
import { Link } from 'react-router-dom'

import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';

import { getUserInfo as getCardUserInfo, issueCard } from '../../lib/cardApi'
import { useToast } from '../../components/ToastProvider';

const useStyles = makeStyles(() => ({
  root: {},
  dialogContent: {
    minWidth: '400px',
  }
}));

type Props = { className?: string, userInfo: IssueCardInput, setUserInfo: React.Dispatch<React.SetStateAction<IssueCardInput>> }
const AccountDetails: FC<Props> = ({ className, userInfo, setUserInfo }) => {

  const classes = useStyles();
  const { showToast } = useToast();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUserInfo({
      ...userInfo,
      [e.target.name]: e.target.value
    });
  };


  const [isValidForm, setIsValidForm] = React.useState(false);

  const checkFormValidation = () => {
    const isValid = Object.values(userInfo).reduce((acc, value) => {
      return !isEmpty(value) && acc;
    }, true)
    setIsValidForm(isValid);
  }

  useEffect(() => {
    checkFormValidation();
  // eslint-disable-next-line
  }, [userInfo])

  const [confirmAddOpen, setConfirmAddOpen] = React.useState(false);
  const [cardNumber, setCardNumber] = React.useState('');
  const [issueResult, setIssueResult] = React.useState({
    cardNumber: '',
    expiration: ''
  });

  const handleConfirmAddOpen = () => {
    setConfirmAddOpen(true);
  };

  const handleConfirmAddCancel = () => {
    setConfirmAddOpen(false);
  };

  const handleConfirmAddConfirm = async () => {
    setConfirmAddOpen(false);
    try {
      const getCardUserInfoResult = await getCardUserInfo(userInfo.id);
      const issueCardResult = await issueCard(userInfo, getCardUserInfoResult.aplnCardNo, getCardUserInfoResult.cardEfctvYm, '11');
      console.log(issueCardResult);
      setCardNumber(getCardUserInfoResult.aplnCardNo);
      setIssueResult({
        cardNumber: issueCardResult.applyCardNo,
        expiration: issueCardResult.aplnEfctvYm
      })

      setResultOpen(true);
    } catch (e) {
      showToast(e.message, 'error');
    };
  };

  const [resultOpen, setResultOpen] = React.useState(false);
  const gender = [
    {
      value: '1',
      label: '남'
    },
    {
      value: '2',
      label: '여'
    },
  ];

  const isForeigner = [
    {
      value: '2',
      label: '내국인'
    },
    {
      value: '1',
      label: '외국인'
    },
  ];

  const isEmpty = (input: string) => {
    return input === '';
  }

  return (
    <Card
      className={clsx(classes.root, className)}
    >
      <form
        autoComplete="off"
        noValidate
      >
        <CardHeader
          title="발급 신청 정보"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={1}
          >
            <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                label="상품명"
                margin="dense"
                name="productName"
                onChange={handleChange}
                required
                value={userInfo.productName}
                variant="outlined"
                error={isEmpty(userInfo.productName)}
                helperText={isEmpty(userInfo.productName) ? 'Required' : ''}
              />
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                label="한글 이름"
                margin="dense"
                name="korName"
                onChange={handleChange}
                required
                value={userInfo.korName}
                variant="outlined"
                error={isEmpty(userInfo.korName)}
                helperText={isEmpty(userInfo.korName) ? 'Required' : ''}
              />
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                label="영문 이름"
                margin="dense"
                name="engName"
                onChange={handleChange}
                required
                value={userInfo.engName}
                variant="outlined"
                error={isEmpty(userInfo.engName)}
                helperText={isEmpty(userInfo.engName) ? 'Required' : ''}
              />
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                label="생년월일"
                margin="dense"
                name="birthday"
                onChange={handleChange}
                required
                value={userInfo.birthday}
                variant="outlined"
                error={isEmpty(userInfo.birthday)}
                helperText={isEmpty(userInfo.birthday) ? 'Required' : ''}
              />
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                label="성별 구분"
                margin="dense"
                name="gender"
                onChange={handleChange}
                required
                variant="outlined"
                select
                SelectProps={{ native: true }}
                value={userInfo.gender}
              >
                {gender.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                label="외국인 구분"
                margin="dense"
                name="isForeigner"
                onChange={handleChange}
                required
                value={userInfo.isForeigner}
                variant="outlined"
                select
                SelectProps={{ native: true }}
              >
                {isForeigner.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                label="휴대폰번호"
                margin="dense"
                name="phoneNumber"
                onChange={handleChange}
                required
                value={userInfo.phoneNumber}
                variant="outlined"
                error={isEmpty(userInfo.phoneNumber)}
                helperText={isEmpty(userInfo.phoneNumber) ? 'Required' : ''}
              />
            </Grid>
            <Grid
              item
              md={8}
              xs={12}
            >
              <TextField
                fullWidth
                label="CI"
                margin="dense"
                name="ci"
                onChange={handleChange}
                required
                value={userInfo.ci}
                variant="outlined"
                error={isEmpty(userInfo.ci)}
                helperText={isEmpty(userInfo.ci) ? 'Required' : ''}
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
            >
              <TextField
                fullWidth
                label="우편번호"
                margin="dense"
                name="zipcode"
                onChange={handleChange}
                required
                type='number'
                value={userInfo.zipcode}
                variant="outlined"
                error={isEmpty(userInfo.zipcode)}
                helperText={isEmpty(userInfo.zipcode) ? 'Required' : ''}
              />
            </Grid>
            <Grid
              item
              md={9}
              xs={12}
            >
              <TextField
                fullWidth
                label="주소"
                margin="dense"
                name="address"
                onChange={handleChange}
                required
                value={userInfo.address}
                variant="outlined"
                error={isEmpty(userInfo.address)}
                helperText={isEmpty(userInfo.address) ? 'Required' : ''}
              />
            </Grid>
            <Grid
              item
              md={2}
              xs={12}
            >
              <TextField
                inputProps={{
                  maxLength: 4,
                }}
                fullWidth
                type="password"
                label="비밀번호"
                margin="dense"
                name="password"
                onChange={handleChange}
                required
                value={userInfo.password}
                variant="outlined"
                error={isEmpty(userInfo.password)}
                helperText={isEmpty(userInfo.password) ? 'Required' : ''}
              />
            </Grid>
            <Grid
              item
              md={2}
              xs={12}
            >
              <TextField
                inputProps={{
                  maxLength: 4,
                }}
                fullWidth
                type="password"
                label="비밀번호확인"
                margin="dense"
                name="passwordConfirm"
                onChange={handleChange}
                required
                value={userInfo.passwordConfirm}
                variant="outlined"
                error={isEmpty(userInfo.passwordConfirm)}
                helperText={isEmpty(userInfo.passwordConfirm) ? 'Required' : ''}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            disabled={!isValidForm}
            color="primary"
            variant="contained"
            onClick={handleConfirmAddOpen}
          >
            발급 신청 
          </Button>
        </CardActions>
      </form>

      <Dialog
        open={confirmAddOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"카드 발급 신청"}</DialogTitle>
        <DialogContent
          className={classes.dialogContent}
        >
          <DialogContentText id="alert-dialog-description">
            해당 정보로 신청하시겠습니까?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmAddCancel} color="primary">
            취소
          </Button>
          <Button onClick={handleConfirmAddConfirm} color="primary" autoFocus>
            확인
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={resultOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"신청 결과"}</DialogTitle>
        <DialogContent
          className={classes.dialogContent}
        >
          <DialogContentText id="alert-dialog-description">
            신청 완료 되었습니다.<br/><br/>
            카드번호: {issueResult.cardNumber}<br/>
            유효기간: {issueResult.expiration.slice(0,4) + '/' + issueResult.expiration.slice(4,6)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Link to={'/carddetail/' + cardNumber}>
            <Button color="primary" autoFocus>
              확인
            </Button>
          </Link>
        </DialogActions>
      </Dialog>

    </Card>
  );
};

export default AccountDetails;