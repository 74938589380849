import client from './client';
import { getRequestHeader } from './common'
import { get } from 'lodash';
import { format } from 'date-fns';

export default async (input: IssueCardInput, cardNumber: string, cardEfctvYm: string, issueType: string, prevCardNo?: string): Promise<BC6521BodyVO> => {
  console.log('6520', getBody(input, cardNumber, cardEfctvYm, issueType, prevCardNo))
  return client.post(`/root/card/issue`, getBody(input, cardNumber, cardEfctvYm, issueType, prevCardNo)).then(res => {
    const statusCode = get(res, 'data.header.rspnCd', undefined);
    console.log(statusCode, res)
    if (!statusCode || statusCode !== '00') {
      throw new Error(`카드번호 채번 실패 - 알수없는 에러`)
    }

    return res.data.body;
  }).catch(e => {
    throw e 
  });
}

const getBody = (input: IssueCardInput, cardNumber: string, cardEfctvYm: string, issueType: string, prevCardNo?: string): BC6520VO => {
  console.log(input)
  console.log(input.birthday)
  
  const clphnBsnnDstgTelNo = input.phoneNumber.replace(/-/gi, "").substr(0,3);
  const clphnDstgTelNo = input.phoneNumber.replace(/-/gi, "").substr(3,input.phoneNumber.length-7);
  const clphnSeqTelNo = input.phoneNumber.replace(/-/gi, "").substr(input.phoneNumber.length - 4, input.phoneNumber.length);
  const birthday = input.birthday.length === 8 ? input.birthday.slice(2,8) :
    (input.birthday.length === 6 ? input.birthday : format(new Date(input.birthday), 'yyMMdd'));
  return {
    header: getRequestHeader('6520', 'BCA'),
    body: {
      hndlInstOpunCd: "123",
      hndlTrmnNo: "123",
      trtmDsCd: "1",
      emgcIsncDsCd: "1",
      aplnCardDsCd: "1",
      aplnIsncDsCd: issueType,
      cardMngOpunCd: "123",
      preCardNo: prevCardNo ? prevCardNo : "",
      applyCardNo: cardNumber,
      selfCardNo: "",
      mbrsKorNm: input.korName,
      mbrsEngNm: input.engName,
      mbrsRlnmNo: "1111",
      pwd: input.password,
      stlmAcctNo: input.id,
      mbrsCorpnNm: "",
      mbrsDeptNm: "",
      cardSndgAddrDsCd: "1",
      bllSndgCd: "1",
      mbrsHomeIntnlTelNo: "02",
      mbrsHomeDstgTelNo: "815",
      mbrsHomeSeqTelNo: "0527",
      homeZipNo: input.zipcode,
      homeAddr: input.address,
      billAddrDsCd: "1",
      thrdBllngIntnlTelNo: "",
      thrdBllngDstgTelNo: "",
      thrdBllngSeqTelNo: "",
      thrdBllngZipNo: "",
      thrdBllngAddr: "",
      rtrmDsCd: "",
      mbrsOfcIntnlTelNo: "",
      mbrsOfcDstgTelNo: "",
      mbrsOfcSeqTelNo: "",
      ofcZipNo: "",
      ofcAddr: "",
      alncAnnualFeeEetCd: "",
      dfrTrfcFnctnYn: "0",
      cardLoanLmt: "",
      dummy1: "",
      faxIntnlTelNo: "",
      faxDstgTelNo: "",
      faxSeqTelNo: "",
      bprIntnlTelNo: "",
      bprDstgTelNo: "",
      bprSeqTelNo: "",
      clphnBsnnDstgTelNo,
      clphnDstgTelNo,
      clphnSeqTelNo,
      stlmDd: "15",
      mbrsTpbsCd: "",
      qlfctCd: "",
      pstCd: "",
      hobby: "",
      rlgn: "",
      gpType: "",
      picCardYn: "",
      carYn: "",
      cardGrd: "",
      sndgTpDsCd: "1",
      cardSndgOpunCd: "1",
      mediaDsCd: "1",
      gndrDsCd: input.gender,
      frgnDsCd: input.isForeigner,
      dwlInfo: "",
      aplctRcipDsCd: "3",
      wrnInfoReAplnCd: "",
      autoUpdDtlCd: "",
      birthDsCd: "",
      birthDt: birthday,
      marryDt: "",
      affiFirmNo: "100128",
      alncCardId: "",
      interIdNo: "",
      rvvOvrsCashIrt: "",
      rvvMinmStlmAmt: "",
      dummy2: "",
      rvvDmstcStlmAmt: "",
      chcnCardEmboDataReqDs: "",
      rvvTmpryBllRate: "",
      lostReIsncRcipDs: "",
      internetCardDs: "",
      isncMnbdDs: "1",
      cmsStlmBnk: "",
      bnkScltCd1: "",
      rvvDsirStlm: "",
      rvvStlmWay: "",
      rvvRcdRvctnDs: "",
      aplnEfctvYm: "202503",
      annualFeeExmpCd: "",
      bnkScltCd2: "",
      cpinNo: "",
      enlsPrsnNm: "",
      enlsDt: "",
      enlsDsCd: "",
      enlsPrsnNo: "",
      opunRgstDt: "",
      cashCardYn: "1",
      msData3: "",
      mbrAplnTotalLmt: "",
      mbrAplnCashSrvcLmt: "",
      mbrAplnLmtDsCd: "",
      yearIncome: "",
      mbrInfoPrvdAgrmYn: "",
      zoopCardDsCd: "",
      ptCardGrd: "",
      cstmSrvcDsCd: "",
      mtrxDsCd: "",
      cstmSrvcType1: "",
      cstmSrvcType2: "",
      cstmSrvcType3: "",
      cstmSrvcType4: "",
      cstmSrvcType5: "",
      cstmSrvcType6: "",
      cstmSrvcType7: "",
      cvv: "",
      mbrFeeRtGrd: "",
      mbrFeeRtRgstDd: "",
      rvvSelDsCd: "",
      rvvAplnStlmAmt: "",
      cashSrvcStlmDsCd: "",
      dummy3: "",
      homeNewAddrDsCd: "1",
      homeRoadNmMgmtNo: "1234",
      homeNewAddr: input.address,
      homeNewDtlAddr: input.address,
      ofcNewAddrDsCd: "",
      ofcRoadNmMgmtNo: "",
      ofcNewAddr: "",
      ofcNewDtlAddr: "",
      thrdBllngNewAddrDsCd: "",
      thrdBllngRoadNmMgmtNo: "",
      thrdBllngNewAddr: "",
      thrdBllngNewDtlAddr: "",
      cardPdGthrAgrmYn: "",
      nmcpSrvcGthrAgrmYn: "",
      unqDstgNoGthrAgrmYn: "",
      fnclGrpPrvdAgrmYn: "",
      crptnUsePrvtPrvdAgrmYn: "",
      alncInstPrvdAgrmYn: "",
      unqDstgNoPrvdAgrmYn: "",
      spcAgrmYn: "",
      unqDstgNoSpcAgrmYn: "1",
      telCrdtCardSlctAgrmYn: "9",
      smsCrdtCardSlctAgrmYn: "1",
      inwrCrdtCardSlctAgrmYn: "1",
      emailCrdtCardSlctAgrmYn: "1",
      dsnDsCd: "",
      orgTrUniqNo: "123",
      dummy4: "",
      trtmRsltDsCd: "1",
      cardEfctvYm,
      trtmDt: format(new Date(), 'yyyyMMdd'),
      trtmTime: format(new Date(), 'HHmmss'),
      rsnNbr1: "",
      rsnNbr2: "",
      rsnNbr3: ""
    }
  }
}