import React, { FC } from 'react';
import clsx from 'clsx';
import { withStyles, makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      // backgroundColor: theme.palette.background.default,
      backgroundColor: '#F4F6F8',
    },
  },
}))(TableRow);

const useStyles = makeStyles(() => ({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

type Props = { className?: string, cardUserInfo: GetCardUserInfoReturn }
const CustomerInfo: FC<Props> = ({ className, cardUserInfo }) => {
  const classes = useStyles();

  return (
    <Card
      className={clsx(classes.root, className)}
    >
      <form>
        <CardHeader
          title="회원 정보"
        />
        <Divider />
        <Table>
          <TableBody>
            <StyledTableRow >
              <StyledTableCell>고객번호</StyledTableCell>
              <StyledTableCell>{cardUserInfo.csno}</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow >
              <StyledTableCell>한글명</StyledTableCell>
              <StyledTableCell>{cardUserInfo.custKorName}</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow >
              <StyledTableCell>영문명</StyledTableCell>
              <StyledTableCell>{cardUserInfo.custEngName}</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow >
              <StyledTableCell>생년월일</StyledTableCell>
              <StyledTableCell>{cardUserInfo.birthDt}</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow >
              <StyledTableCell>성별구분</StyledTableCell>
              <StyledTableCell>{(cardUserInfo.sexDvsnCd === '1') ? '남자' : '여자'}</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow >
              <StyledTableCell>외국인구분</StyledTableCell>
              <StyledTableCell>{(cardUserInfo.foreignerDvsnCd === '1') ? '외국인' : '내국인'}</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow >
              <StyledTableCell>휴대폰번호</StyledTableCell>
              <StyledTableCell>{cardUserInfo.phone}</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow >
              <StyledTableCell>배송지주소</StyledTableCell>
              <StyledTableCell>{cardUserInfo.addr}</StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
        <Divider />
      </form>
    </Card>
  );
};

export default CustomerInfo;
