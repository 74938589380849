import client from './client';
import { getRequestHeader } from './common'
import { get } from 'lodash';
import { format } from 'date-fns';

export default async (input: AccidentInput): Promise<BC3011BodyVO> => {
  console.log('3010', getBody(input))
  return client.post(`/root/cardAcdt/receive`, getBody(input)).then(res => {
    const statusCode = get(res, 'data.header.rspnCd', undefined);
    console.log(statusCode, res)
    if (!statusCode || statusCode !== '00') {
      throw new Error(`사고등록  실패 - 알수없는 에러`)
    }

    return res.data.body;
  }).catch(e => {
    throw e 
  });
}

const getBody = (input: AccidentInput): BC3010VO => {
  let body: BC3010BodyVO = {
    trmnlNbr: '1',
    mnbdNbr: input.mnbdNbr,
    mnbdDsCd: '2',
    acdntCd: input.acdntCd,
    regDt: format(new Date(), 'yyyyMMdd'),
    regHms: format(new Date(), 'HHmmss'),
    acdntRcipNbr: '123',
    acdntRcipDsCd: '07',
    reptPrsnNm: '이강준', // TODO
    cntctTelNbr: '01012341234',
  }

  if (input.acdntCd === '111') {
    body = {
      ...body,
      lostDt: format(new Date(), 'yyyyMMdd'),
      lostRegnNm: '서울',
      abrdLostRbrYn: 'N'
    }
  }

  return {
    header: getRequestHeader('3010', 'BCA'),
    body
  }
}