import React, { useState, FC } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Theme, makeStyles } from '@material-ui/core';
import {
  Card,
  CardContent,
  CardActions,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 0
  },
  dialogContent: {
    minWidth: '400px',
  },
  icon: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  isFinalContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1),
  },
}));

type MerchantDetail = {
  id: string;
  name: string;
  sector: string;
}
type Props = { merchants: MerchantDetail[] }
const MerchantTable: FC<Props> = ({ merchants }) => {
  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number(event.target.value));
  };

  return (
    <Card>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>가맹점명</TableCell>
                  <TableCell>업종</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {merchants.slice(0, rowsPerPage).map(merchant => (
                  <TableRow
                    hover
                    key={merchant.id}
                  >
                    <TableCell>{merchant.id}</TableCell>
                    <TableCell>{merchant.name}</TableCell>
                    <TableCell>{merchant.sector}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={merchants.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

export default MerchantTable;
