import client from './client';
import { getRequestHeader } from './common';
import { get } from 'lodash';

const getBody = (customerId: string, isReissue?: boolean, preCardNo?: string): BC6406VO => {
  return {
    header: getRequestHeader('6406', 'BCA'),
    body: {
      cardIssuClss: "2",
      tpClss: "2",
      cardOwnrClss: "1",
      affiFirmNo: "100128",
      stlmAcctNo: customerId,
      cardReIsncYn: isReissue? "2" : "1",
      cashCardYn: "1",
      hndlInstOpunCd: "123",
      hndlInstPrsnNo: "123",
      fmlRlnmNo: "",
      preCardNo: preCardNo ? preCardNo : '',
      dummy: ""
    }
  }
}

export default async (customerId: string, isReissue?: boolean, preCardNo?: string): Promise<BC6407BodyVO> => {
  console.log(6406, getBody(customerId, isReissue, preCardNo));
  return client.post(`/root/card/userInfoSelect`, getBody(customerId, isReissue, preCardNo)).then(res => {
    const inblRsn = get(res, 'data.body.inblRsn');
    console.log(res.data, inblRsn)
    if (!inblRsn) {
      throw new Error(`카드번호 채번 실패 - 알수없는 에러`)
    }

    if (inblRsn !== '0000') {
      throw new Error(`카드번호 채번 실패 - ${res.data.body.inblRsnCmt}`)
    }

    return res.data.body;
  });
}