import client from './client';
import { get, find, orderBy } from 'lodash';

export default async (input: GetCardListInput): Promise<CardInfo[]> => {
  if (!input.csno && !input.cardNo) {
    throw new Error(`카드정보 조회 실패 - 입력값이 없습니다`)
  }

  return client.post(`/root/card/cardInfoListSelect`, input).then(res => {
    const cardList = get(res, 'data.CardInfo');
    if (!cardList) {
      throw new Error(`카드정보 조회 실패`)
    }

    if (input.cardNo) {
      return find(cardList, item => { return item.cardNo === input.cardNo})
    }

    if (input.csno) {
      return orderBy(cardList, ['cardNo'], ['desc']);
    }

    throw new Error(`카드정보 조회 실패`)
  });
}