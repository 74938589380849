import React, { useState } from 'react';
import { Grid, Theme, makeStyles } from '@material-ui/core';
import { RouteComponentProps } from "react-router";

import { UserInfo, CardList } from '../../components';

import CustomerToolbar from './CustomerToolbar';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

type MatchParams = { customerId: string }
const Customer: React.SFC<RouteComponentProps<MatchParams>> = ({ match }) => {
  const { customerId } = match.params;

  const [cardUserInfo, setCardUserInfo] = useState({
    csno: '',
    custKorName: '',
    custEngName: '', 
    birthDt: '',
    sexDvsnCd: '',
    foreignerDvsnCd: '',
    phone: '',
    emalAddr: '',
    addr: '',
    zipCode: '',
  });

  const [cards, setCards] = useState<CardInfo[]>([]);

  const [isAccountDetailsVisible, setIsAccountDetailsVisible] = useState(false);

  // useEffect(() => {
  //   const setCustomerDetail = async (customerId: string) => {
  //     const result = await getChaiUserInfo(customerId);
  //     console.log(result);
  //     setCardUserInfo({
  //       ...cardUserInfo,
  //       ...result
  //     })
  //     setIsAccountDetailsVisible(true);
  //   }
  //   if (customerId && !isAccountDetailsVisible) {
  //     console.log(customerId, isAccountDetailsVisible)
  //     setCustomerDetail(customerId);
  //   }
  // })

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CustomerToolbar
        cid={customerId}
        cardUserInfo={cardUserInfo}
        setCardUserInfo={setCardUserInfo}
        cards={cards}
        setCards={setCards}
        isAccountDetailsVisible={isAccountDetailsVisible}
        setIsAccountDetailsVisible={setIsAccountDetailsVisible}
      />

      {isAccountDetailsVisible &&
      <div className={classes.content}>
        <Grid
          container
          spacing={4}
        >
          <Grid
            item
            md={5}
            xs={12}
          >
            <UserInfo cardUserInfo={cardUserInfo}/>
          </Grid>
          <Grid
            item
            md={8}
            xs={12}
          >
          </Grid>
          <Grid
            item
            md={12}
            xs={12}
          >
            <CardList cards={cards} title='보유 카드' />
          </Grid>
        </Grid>
      </div>
      }
    </div>
  );
};

export default Customer;
