import React, { FC, useState, useEffect } from 'react';
import clsx from 'clsx';
import { Theme, makeStyles } from '@material-ui/core';
import { Button } from '@material-ui/core';

import { SearchInput } from '../../components';
import { getCardUserInfo, getCardList } from '../../lib/cardApi'
import { useToast } from '../../components/ToastProvider';


const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

type Props = {
  className?: string,
  cid: string,
  cardUserInfo: GetCardUserInfoReturn,
  setCardUserInfo: React.Dispatch<React.SetStateAction<GetCardUserInfoReturn>>,
  cards: CardInfo[],
  setCards: React.Dispatch<React.SetStateAction<CardInfo[]>>,
  isAccountDetailsVisible: boolean;
  setIsAccountDetailsVisible: React.Dispatch<React.SetStateAction<boolean>>
}
const UsersToolbar: FC<Props> = ({ className, cid='', cardUserInfo, setCardUserInfo, setCards, isAccountDetailsVisible, setIsAccountDetailsVisible }) => {
  const classes = useStyles();
  const { showToast } = useToast();

  const [customerId, setCustomerId] = useState(cid);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomerId(event.target.value);
  };

  const setCustomerDetail = async () => {
    try {
      const cardUserInfoRes = await getCardUserInfo({ csno: customerId });
      console.log(cardUserInfoRes)
      setCardUserInfo({
        ...cardUserInfo,
        ...cardUserInfoRes
      })

      const cardsRes = await getCardList({ csno: customerId });
      console.log(cardsRes)
      setCards(cardsRes);

      setIsAccountDetailsVisible(true);
    } catch (e) {
      showToast(e.message, 'error')
    }
  };

  useEffect(() => {
    if (customerId && !isAccountDetailsVisible) {
      console.log(customerId, isAccountDetailsVisible)
      setCustomerDetail();
    }
  // eslint-disable-next-line
  }, [])

  return (
    <div
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
        <span className={classes.spacer} />
      </div>
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder="User ID"
          handleChange={handleChange}
          value={customerId}
        />
        <Button
          color="primary"
          variant="contained"
          onClick={setCustomerDetail}
        >
          Search 
        </Button>
      </div>
    </div>
  );
};

export default UsersToolbar;
